<template>
  <header class="container">
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item">
          <img src="../assets/hf_logo.png" />
        </a>
        <div class="my-3 has-text-left">
          <h3 class="title">{{ title }}</h3>
          <h3 class="subtitle">Hike &amp; Fly Berguedà</h3>
        </div>
        <navbar-burger-botton
          v-bind:class="{ 'is-active': isOpen }"
          v-on:click="isOpen = !isOpen"
        ></navbar-burger-botton>
      </div>
      <div class="navbar-menu" v-bind:class="{ 'is-active': isOpen }">
        <div class="navbar-end">
          <slot />
          <router-link to="/" class="navbar-item">Edició 2025</router-link>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
import NavbarBurgerBotton from "./NavbarBurgerBotton.vue";
export default {
  props: {
    title: String,
  },
  components: {
    NavbarBurgerBotton,
  },
  data() {
    return {
      isOpen: false,
    };
  },
};
</script>
